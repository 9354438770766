<template>
  <div class="video">
    <container :gutter="30">
      <div>
        <el-carousel :interval="2000" arrow="always">
          <el-carousel-item>
            <img
              width="100%"
              src="https://dummyimage.com/145x145/96ce54"
              alt=""
              sizes=""
              srcset=""
            />
          </el-carousel-item>
          <el-carousel-item>
            <img
              width="100%"
              src="https://dummyimage.com/145x145/f05654"
              alt=""
              sizes=""
              srcset=""
            />
          </el-carousel-item>

          <el-carousel-item>
            <img
              width="100%"
              src="https://dummyimage.com/145x145/1bd1a5"
              alt=""
              sizes=""
              srcset=""
            />
          </el-carousel-item>
        </el-carousel>

        <div class="new-video-wrap">
          <div class="title">最新视频</div>

          <div class="video-list">
            <el-row :gutter="20">
              <el-col :span="12" v-for="i in 10" :key="i">
                <div class="video-box">
                  <div class="poster">
                    <img
                      style="width: 100%"
                      src="https://dummyimage.com/335x188/1bd1a5"
                      alt=""
                      srcset=""
                    />
                  </div>
                  <div class="desc">
                    <div class="desc-title">
                      监管部门进一步规范对赌协议，企业IPO审核会受影响吗？
                    </div>
                    <div class="desc-text">
                      在市场人士看来，监管层此举意在进一步规范IPO公司涉及对赌安排的信息披露要求。
                    </div>
                    <div class="desc-name">
                      <span>锌财经</span>
                      <span>
                        <i class="el-icon-time"></i>
                        1小时前
                      </span>
                    </div>
                  </div>
                </div>
              </el-col>
            </el-row>
          </div>
        </div>
      </div>
      <div slot="side" class="author-wrap">
        <div class="title">推荐作者</div>
        <div class="author" v-for="i in 6" :key="i">
          <div class="avatar">
            <img src="https://dummyimage.com/42x42/4b5cc4" alt="" srcset="" />
          </div>
          <div class="desc">
            <div class="desc-title">阿牛读财</div>
            <div class="desc-text">“M+女神天团”——给你专业好看的财经新闻</div>
          </div>
        </div>
      </div>
    </container>
  </div>
</template>

<script>
import Container from "./Container.vue";
export default {
  components: { Container },
};
</script>

<style lang="scss" scoped>
.video {
  padding: 30px 0;

  .author-wrap {
    position: sticky;
    top: 95px;

    .title {
      font-size: 20px;
    }

    .author {
      padding: 20px 0;
      position: relative;

      & + .author {
        border-top: 1px solid #ededed;
      }

      .avatar {
        position: absolute;
        left: 0;
        top: 20px;

        border-radius: 50%;
        overflow: hidden;
      }

      .desc {
        padding-left: 60px;

        .desc-title {
          font-size: 14px;
          height: 22px;
          line-height: 22px;

          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        .desc-text {
          color: #666;
          padding-top: 2px;
          font-size: 13px;

          line-height: 18px;
          max-height: 54px;

          word-break: break-all;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
        }
      }
    }
  }

  .new-video-wrap {
    .title {
      font-size: 20px;
      margin-top: 30px;
      margin-bottom: 10px;
    }

    .video-box {
      margin-bottom: 20px;

      .desc {
        height: 120px;
        position: relative;

        .desc-title,
        .desc-text {
          word-break: break-all;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
        }

        .desc-title {
          margin-top: 10px;

          font-size: 18px;

          line-height: 24px;
          max-height: 48px;
        }

        .desc-text {
          margin-top: 8px;

          font-size: 14px;

          line-height: 16px;
          max-height: 32px;
          color: #666;
        }

        .desc-name {
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0;
          font-size: 14px;
          color: #999;
          display: flex;
          justify-content: space-between;
        }
      }
    }
  }
}
</style>